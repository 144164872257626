import * as React from "react"
import Layout from "../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "../modules/imageComponent";
import { graphql } from "gatsby"

const BlogPage = ({
       data: {
           allMarkdownRemark: { edges },
       },
       }) => {
        const Posts = edges
            .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
            .map(edge => <div className={'row mt-5'}>
                <div className={'col-12'}>
                    <AniLink fade duration={0.1} hex={'#000000'} to={edge.node.frontmatter.slug}>
                        <div className={'row article align-items-center'}>
                            <div className={'col col-12 col-md-6'}>
                                <div className={'image'}>
                                    <Image alt={edge.node.frontmatter.title} filename={edge.node.frontmatter.image}/>
                                </div>
                            </div>
                            <div className={'col col-12 col-md-6'}>
                                <div className={'category'}>{edge.node.frontmatter.category}</div>
                                <h3>{edge.node.frontmatter.title}</h3>
                                {edge.node.frontmatter.description}
                                <div className={'button small'}>Read more</div>
                            </div>
                        </div>
                    </AniLink>
                </div>
            </div>)

    return <Layout className={'blog'} title={'Blog'}>
            <section>
                <div className={'container'}>
                    <h1 className={'main'}>Blog</h1>

                    {Posts}
                </div>
            </section>
        </Layout>
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            image
            category
          }
        }
      }
    }
  }
`
